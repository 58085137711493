function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {

        const first_category = $('.questions-1');
        const second_category = $('.questions-2');

        $('.faqs-all').on('click', function () {
            first_category.css('display', 'block');
            second_category.css('display', 'block');
            changeButtonClass('faqs-all');
        });

        $('.faqs-category-1').on('click', function () {
            first_category.css('display', 'block');
            second_category.css('display', 'none');
            changeButtonClass('faqs-category-1');
        });

        $('.faqs-category-2').on('click', function () {
            second_category.css('display', 'block');
            first_category.css('display', 'none');
            changeButtonClass('faqs-category-2');
        });

        function changeButtonClass($button_id) {
            $faqs_button = $('.' + $button_id + '');
            if ($faqs_button.hasClass('white-button')) {
                $faqs_button.removeClass('white-button');
                $faqs_button.addClass('blue-button');
            }
            $other_filters = $('.faqs-filter');
            $other_filters.not($faqs_button).removeClass('blue-button');
            $other_filters.not($faqs_button).addClass('white-button');
        }

    });
})(jQuery);
